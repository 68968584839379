import React from 'react'
import logo from '../../assets/images/logo.svg'

const Header = () => {
    return (
        <div className="pb-10">
            <div>
                <img src={logo} alt="logo" className="2xl:w-40" />
            </div>
        </div>
    )
}

export default Header
