import React from 'react'
import MapImages from '../../assets/images/map.svg'
import useWindowDimensions from '../../hooks/getWindowDimensions'

const Map = () => {
    const {
        // height,
        width
    } = useWindowDimensions()
    return (
        <div>
            <img
                src={MapImages}
                alt="playstore"
                style={{
                    width: width,
                }}
            />
        </div>
    )
}

export default Map
