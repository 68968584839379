import React from 'react'
import Header from '../components/header/Header'
import Hero from '../components/hero/Hero'
import Map from '../components/map/Map'
import Footer from '../components/footer/Footer'
import useWindowDimensions from '../hooks/getWindowDimensions'

const Home = () => {
    const { height, width } = useWindowDimensions()
    return (
        <div
            style={{
                height: height,
            }}
        >
            <div
                className="px-4 md:px-10 lg:px-40 pt-10 fixed z-20 2xl:mt-12 2xl:px-52"
                style={{
                    width: width,
                }}
            >
                <Header />
                <Hero />
            </div>
            <div
                className="fixed z-10 bottom-0"
                style={{
                    width: width,
                }}
            >
                <Map />
                <Footer />
            </div>
        </div>
    )
}

export default Home
