import React from 'react'
import Home from './pages/Home'

const App = () => {
    return (
        <div>
            <Home />
        </div>
    )
}

export default App
