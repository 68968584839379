import React from 'react'

const Footer = () => {
    return (
        <div className="flex items-center justify-center bg-[#EDEDED]">
            <div className="text-[#262626] text-[12px] py-1 leading-[21px] font-light">
                Copyright © 2022 Questeon. All Rights Reserved.
            </div>
        </div>
    )
}

export default Footer
